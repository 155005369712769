import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const Home = lazy(() => import("../pages/Home/index"));
const ChatForm = lazy(() => import("../pages/ChatForm/index"));

const RoutePaths = [
  { path: "/", component: Home, meta: { authRoute: false } },
  { path: "/chatForm", component: ChatForm, meta: { authRoute: false } },
];

function Router() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {RoutePaths.map((route, index) => {
            const Component = route.component;
            return (
              <Route key={index} path={route.path} element={<Component />} />
            );
          })}
        </Routes>
      </Suspense>
    </>
  );
}

export default Router;
